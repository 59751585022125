<template>
  <main class="flex-1 overflow-x-hidden overflow-y-auto px-2">
    <div
      style="font-size: 24px; /* Adjust the font size as needed */
  font-weight: bold;"
    >
      <p>Processing...</p>
    </div>
  </main>
</template>
<script>
export default {
  data() {
    this.username1;
    var user_id = localStorage.getItem("user-id");
    this.$axios.get(`${this.$api}/username/${user_id}`).then(res => {
      //   alert(res.data.data[0].username);
      console.log(res.data.data[0].username);
      this.username1 = res.data.data[0].username;
      console.log(this.username1);
      this.$axios
        .post(`https://varilytics.com/api/deletefavourite_events_new`, {
          id: this.$route.params.id,
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            "Access-Control-Allow-Methods":
              "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
          }
        })
        .then(result => {
          //var re = JSON.parse(result);
          // alert(result.data.message);
          //   window.location.reload();
          //alert(re.message);
          //this.fa-heart.color = this.colors.green;
          console.log(result);
        });
    });
    // alert('Event Add to your favourite list.');
    // window.location.href = "../admin/tickets";
    setTimeout(() => {
      alert("Favourite Event & Calendar Removed From List.");
      window.location.href = "../admin/tickets";
    }, 5000);
  }
};
</script>
